<template>
  <section class="mt-3">
    <p class="mb-2">Angaben gemäß § 5 TMG:</p>
    <p class="mb-2">
      <strong>PLUG&amp;YAY GmbH</strong><br />
      Am Großen Teich 20<br />
      58640 Iserlohn<br />
    </p>
    <p class="mb-2">
      Kontakt:<br />
      02371 6589090 (<strong
        >wenn Sie Hilfe brauchen, wenden Sie sich bitte direkt an uns per
        E-Mail:
        <a href="mailto:hello@plugandyay.de">hello@plugandyay.de</a></strong
      >)<br />
      <a href="mailto:hello@plugandyay.de">hello@plugandyay.de</a><br />
      <a href="https://plugandyay.de">www.plugandyay.de</a>
    </p>
    <p class="mb-2">
      Registergericht: Amtsgericht Iserlohn <br />
      Registernummer: HRB 9567 <br />
      Vertretungsberechtigter Geschäftsführer: Jonas Trettin <br />
      Umsatzsteuer-Identifikationsnummer nach §27a UStG: DE328867519
    </p>
    <p class="mt-3">
      <strong>Online-Streitbelegung und alternative Streitschlichtung</strong>
    </p>

    <p>
      (1) Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit, die unter dem nachfolgendem Link
      abrufbar ist:
      <a href="http://ec.europa.eu/consumers/odr/" target="_blank"
        >http://ec.europa.eu/consumers/odr/</a
      >
      Diese Plattform soll Verbrauchern die Möglichkeit bieten, Streitigkeiten
      aus dem Online-Kaufvertrag außergerichtlich zu klären. Unsere
      E-Mail-Adresse lautet
      <a href="mailto:info@ticketingsolutions.de">info@ticketingsolutions.de</a>
    </p>

    <p>
      (2) Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer
      anerkannten Verbraucherschlichtungsstelle teil.
    </p>
  </section>
</template>

<script>
export default {
  name: "Imprint",
};
</script>

<style></style>
