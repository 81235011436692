<template>
  <section class="mt-3">
    <div class="container">
      <div v-if="$store.getters.user == null">
        <figure class="image mb-5">
          <img
            src="../assets/iec-karte-logo.svg"
            style="max-width: 50vw; margin: 0 auto"
            class="pt-4 pb-2"
          />
        </figure>
        <h3 class="has-text-black">Willkommen!</h3>
        <p>
          Um alle Informationen zu Deiner <iec-card-logo /> sehen zu können,
          musst Du entweder den QR-Code auf Deiner Karte mit Deiner Foto-App
          scannen oder Dich mit Deinem Kundenkonto anmelden.
        </p>
        <p class="mt-3">
          <router-link to="/login" class="button">Anmelden</router-link>
        </p>
      </div>
      <div v-else>
        <h2 class="has-text-black is-size-4 mb-2">Willkommen, {{ $store.getters.user.payload.first_name }}!</h2>
        <h3 class="has-text-black mb-4">Deine Bonuspucks</h3>
        <div class="columns">
          <div class="column is-4">
            <div class="card mb-5">
              <div class="card-content">
                <div class="columns is-mobile">
                  <div class="column is-3">
                    <b-icon pack="fas" icon="hockey-puck" size="is-large" />
                  </div>
                  <div class="column is-6">
                    <span class="has-text-weight-bold has-text-black">{{
                      Number(loyalityPoints).toLocaleString()
                    }}</span
                    ><br />
                    Bonuspucks
                  </div>
                  <div class="column is-2">
                    <router-link to="/loyalitypoints">
                      <b-icon pack="fas" icon="angle-right" size="is-large" />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3 class="has-text-black mb-4">
          Deine verknüpften <iec-card-logo :plural="true" />
        </h3>
        <b-loading :active="isLoading" />
        <div class="columns is-multiline">
          <div class="column is-4" v-for="card in cards" :key="card.id">
            <iec-card :cardCode="card.digital_card_code" />
          </div>
        </div>
        <p v-if="!isLoading && cards.length == 0">
          Du hast noch keine <iec-card-logo /> mit Deinem Kundenkonto verknüpft.
          Um eine <iec-card-logo /> zu verknüpfen, musst du einfach nur den
          QR-Code auf der Karte mit der Foto-App scannen.
        </p>
      </div>
      <div>
        <f-a-q />
      </div>
    </div>
  </section>
</template>

<script>
import FAQ from "./FAQ.vue";
import IecCard from "./IecCard.vue";
import IecCardLogo from "./IecCardLogo.vue";
export default {
  name: "Home",
  components: { IecCardLogo, IecCard, FAQ },
  data() {
    return {
      isLoading: false,
      loyalityPoints: 0,
      cards: [],
      isOpen: 0      
    };
  },
  methods: {
    init() {
      if (this.$store.getters.user != null) {
        this.loadCardsForAccount();
        this.loadLoyalityPoints();
      }
    },
    loadLoyalityPoints() {
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/account/loyality")
        .then((response) => {
          this.loyalityPoints = response.data;
        });
    },
    loadCardsForAccount() {
      this.isLoading = true;
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/account/cards")
        .then((response) => {
          this.cards = [];

          response.data.forEach((item) => {          
            this.cards.push(item);            
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
