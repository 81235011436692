<template>
  <span class="iec-card-logo">
    <span style="color: #1E2A51">i</span><span style="color: #BD1818">e</span
    ><span class="has-text-black">c-karte<span v-if="plural">n</span></span>
  </span>
</template>

<script>
export default {
  name: "IecCardLogo",
  props: {
    plural: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.iec-card-logo {  
  font-weight: 800;
  font-family: Montserrat, sans-serif;
  font-style: italic;
}
</style>
