<template>
  <b-navbar wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="../assets/iec-karte-logo.svg" class="brand-logo" />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" to="/">
        <b-icon
          pack="fas"
          icon="qrcode"
          class="mr-1"
        />Meine&nbsp;<iec-card-logo :plural="true" />
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/loyalitypoints">
        <b-icon pack="fas" icon="hockey-puck" class="mr-1" />Meine Bonuspucks
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/login"
        v-if="$store.getters.user != null"
      >
        Abmelden
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="a" href="https://iec.de" class="is-hidden-mobile">
        <img
          src="https://ticketingsolutions.canto.global/direct/image/uh2mcvas5h63h31lbl8khtm17t/rUw5hP7AokkMaCsDh3rkiW0P_3g/m800/800"
          style="height: 5rem; max-height: 5rem"
        />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import IecCardLogo from "./IecCardLogo.vue";
export default {
  name: "BaseHeader",
  components: { IecCardLogo },
};
</script>

<style>
.navbar-brand {
  background: url("../assets/header.svg") center center no-repeat;
  background-size: cover;
}

.brand-logo {
  display: none;
}

.navbar .navbar-brand {
  min-height: 10rem !important;
}

@media only screen and (min-width: 768px) {
  .navbar-brand {
    background: none;
  }

  .brand-logo {
    width: 10rem;
    display: block;
  }

  .navbar .navbar-brand {
    min-height: 5rem !important;
  }
}
</style>
